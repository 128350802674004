// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useState, useEffect, useRef } from 'react';

import { Row, Col } from 'antd/lib/grid';
import { LinkOutlined } from '@ant-design/icons';
import Slider from 'antd/lib/slider';
import InputNumber from 'antd/lib/input-number';
import Input from 'antd/lib/input';
import Text from 'antd/lib/typography/Text';

import CVATTooltip from 'components/common/cvat-tooltip';
import { clamp } from 'utils/math';
import { useSelector } from 'react-redux';
import serverProxy from '../../../../../cvat-core/src/server-proxy';
import StartMatchButton from './satrt-match-button';

interface Props {
    startFrame: number;
    stopFrame: number;
    frameNumber: number;
    frameFilename: string;
    focusFrameInputShortcut: string;
    inputFrameRef: React.RefObject<Input>;
    onSliderChange(value: number): void;
    onInputChange(value: number): void;
    onURLIconClick(): void;
}

function PlayerNavigation(props: Props): JSX.Element {
    const {
        startFrame,
        stopFrame,
        frameNumber,
        frameFilename,
        focusFrameInputShortcut,
        inputFrameRef,
        onSliderChange,
        onInputChange,
        onURLIconClick,
    } = props;

    const playerRef = useRef();
    const { requestedId } = useSelector((state) => state.annotation.job);
    const { matchStart } = useSelector(state => state.annotation.job.instance)

    const time = useSelector((state) => state.startMatchTime.time);

    const [frameInputValue, setFrameInputValue] = useState<number>(frameNumber);
    const [allMarks, setAllMarks] = useState([]);
    const [secondsView, setSecondsView] = useState(0);
    const [minutesView, setMinutesView] = useState(0);
    const [startMatchTime, setStartMatchTime] = useState(matchStart);

    useEffect(() => {
        if (frameNumber !== frameInputValue) {
            setFrameInputValue(frameNumber);
        }
    }, [frameNumber]);

    useEffect(() => {
        videoTimer();
    }, [frameInputValue]);

    useEffect(() => {
        getAllMarks();
    }, []);
    useEffect(() => {
        if (time !== null) {
            setStartMatchTime(time)
        }
    }, [time])

    async function getAllMarks() {
        const result = await serverProxy.jobs.getNotCorrectedFrames(requestedId);
        setAllMarks(result);
    }


    const totalMinutesOfMatch = Math.floor((stopFrame - startMatchTime) / (28 * 60));// 28*60 = minute
    const oneMinuteFrameWidth = (350 - ((350 / stopFrame) * startMatchTime)) / totalMinutesOfMatch;

    const minutes: number[] = [];
    for (let i = 0; i < totalMinutesOfMatch; i++) {
        minutes.push(i);
    }

    const overMinute = Math.floor((frameInputValue / 28) / 60);
    const playSeconds = Math.round(frameInputValue / 28 - (60 * overMinute));
    const playMinutes = Math.floor(frameInputValue / (28 * 60));


    function videoTimer(): void {
        setSecondsView(playSeconds);
        setMinutesView(playMinutes);
    }

    return (
        <>
            <Col className='cvat-player-controls'>
                <Row align='bottom'>
                    <Col>
                        {allMarks.map(({ not_corrected_start, not_corrected_end }, index) => (
                            <div
                                key={`${index}_frame`}
                                style={{
                                    pointerEvents: 'none',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    position: 'absolute',
                                    top: '4px',
                                    left: `calc(350px/${stopFrame} * ${not_corrected_start})`,
                                    background: '#ec3737',
                                    width: `calc(350px/${stopFrame} * (${not_corrected_end} - ${not_corrected_start}))`,
                                    height: '4px',
                                    zIndex: '2',
                                    borderRadius: '10px',
                                }}
                            >
                                <span style={{
                                    display: 'block',
                                    position: 'absolute',
                                    left: '-4px',
                                    top: '-1.5px',
                                    width: '7px',
                                    height: '7px',
                                    borderRadius: '50%',
                                    border: '2px solid rgba(242, 85, 85, 1)',
                                    background: '#ec3737',
                                }}
                                />
                                <span style={{
                                    display: 'block',
                                    position: 'absolute',
                                    right: '-4px',
                                    top: '-1.5px',
                                    width: '7px',
                                    height: '7px',
                                    borderRadius: '50%',
                                    border: '2px solid rgba(242, 85, 85, 1)',
                                    background: '#ec3737',
                                }}
                                />

                            </div>
                        ))}
                        {startMatchTime !== null && (
                            <>
                                <span style={{
                                    pointerEvents: 'none',
                                    position: 'absolute',
                                    top: '2.5px',
                                    left: `calc(350px/${stopFrame} * ( ${startMatchTime} - 3.5))`,
                                    background: 'yellow',
                                    borderRadius: '50%',
                                    border: '2px solid blue',
                                    width: '8px',
                                    height: '7px',
                                    zIndex: '2',
                                }}
                                />
                                <span style={{
                                    pointerEvents: 'none',
                                    position: 'absolute',
                                    top: '-20px',
                                    left: `calc(350px/${stopFrame} * ${startMatchTime} - 27px)`,
                                    zIndex: '1',
                                    fontSize: '12px',
                                }}
                                >
                                    Match start
                                </span>
                                {
                                    minutes.map((item, index) => (
                                        <div
                                            key={`${index}_minute`}
                                            style={{
                                                pointerEvents: 'none',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                position: 'absolute',
                                                background: 'blue',
                                                top: '4px',
                                                left: `calc((350px/${stopFrame} * ${startMatchTime}) + (${oneMinuteFrameWidth}px * ${item}))`,
                                                width: `${oneMinuteFrameWidth}px`,
                                                height: '4px',
                                                zIndex: '1',
                                                borderRadius: '10px',
                                            }}
                                        >
                                            {item !== minutes[0] ? (
                                                <span style={{
                                                    display: 'block',
                                                    position: 'absolute',
                                                    left: '-4px',
                                                    top: '-1.5px',
                                                    width: '7px',
                                                    height: '7px',
                                                    borderRadius: '50%',
                                                    border: '2px solid blue',
                                                    background: 'blue',
                                                }}
                                                />
                                            ) : (
                                                <span style={{
                                                    display: 'block',
                                                    position: 'absolute',
                                                    left: '-4px',
                                                    top: '-1.5px',
                                                    width: '7px',
                                                    height: '7px',
                                                    borderRadius: '50%',
                                                    background: 'transparrent',
                                                }}
                                                />
                                            )}
                                            {item !== minutes.length - 1 ? (
                                                <span style={{
                                                    display: 'block',
                                                    position: 'absolute',
                                                    right: '-4px',
                                                    top: '-1.5px',
                                                    width: '7px',
                                                    height: '7px',
                                                    borderRadius: '50%',
                                                    background: 'transparent',
                                                }}
                                                />
                                            ) : (
                                                <span style={{
                                                    display: 'block',
                                                    position: 'absolute',
                                                    right: '-4px',
                                                    top: '-1.5px',
                                                    width: '7px',
                                                    height: '7px',
                                                    borderRadius: '50%',
                                                    border: '2px solid blue',
                                                    background: 'blue',
                                                }}
                                                />
                                            )}

                                        </div>
                                    ))
                                }
                            </>
                        )}
                        <Slider
                            ref={playerRef}
                            className='cvat-player-slider'
                            min={startFrame}
                            handleStyle={{ zIndex: '3' }}
                            max={stopFrame}
                            value={frameNumber || 0}
                            onChange={onSliderChange}
                        />
                    </Col>
                </Row>
                <Row justify='center'>
                    <Col className='cvat-player-filename-wrapper'>
                        <p style={{ marginRight: '10px' }}>
                            {playMinutes < 10 ? `0${minutesView}` : minutesView}
                            :
                            {playSeconds < 10 ? `0${secondsView}` : secondsView}
                        </p>
                    </Col>
                    <Col className='cvat-player-filename-wrapper'>
                        <CVATTooltip title={frameFilename}>
                            <Text type='secondary'>{frameFilename}</Text>
                        </CVATTooltip>
                    </Col>
                    <Col offset={1}>
                        <CVATTooltip title='Create frame URL'>
                            <LinkOutlined className='cvat-player-frame-url-icon' onClick={onURLIconClick} />
                        </CVATTooltip>
                    </Col>
                </Row>
            </Col>
            <Col>
                <CVATTooltip title={`Press ${focusFrameInputShortcut} to focus here`}>
                    <InputNumber
                        ref={inputFrameRef}
                        className='cvat-player-frame-selector'
                        type='number'
                        value={frameInputValue}
                        onChange={(value: number | undefined | string | null) => {
                            if (typeof value !== 'undefined' && value !== null) {
                                setFrameInputValue(Math.floor(clamp(+value, startFrame, stopFrame)));
                            }
                        }}
                        onBlur={() => {
                            onInputChange(frameInputValue);
                        }}
                        onPressEnter={() => {
                            onInputChange(frameInputValue);
                        }}
                    />
                </CVATTooltip>
            </Col>
            <StartMatchButton startPosition={frameInputValue} />
        </>
    );
}

export default React.memo(PlayerNavigation);
