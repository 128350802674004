// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
import React from 'react';
import Menu from 'antd/lib/menu';
import { useSelector } from 'react-redux';

interface Props {
    onUndo(): void;
    onRedo(): void;

}
function HistoryMenu(props: Props): JSX.Element {
    const {
        onRedo,
        onUndo,

    } = props;
    const { undo, redo } = useSelector((state) => state.annotation.annotations.history);

    const allUndo: any[] = [];
    const allRedo: any[] = [];

    undo.map((item) => allUndo.push(item.slice(0, 1)));
    redo.map((item) => allRedo.push(item.slice(0, 1)));

    function onUndoClick(e): void {
        const historyItemNumber = Number(e.key);

        for (let index = 1; index <= historyItemNumber; index++) {
            onUndo();
        }
    }

    function onRedoClick(e): void {
        const historyItemNumber = Number(e.key) - 9;

        for (let index = 1; index <= historyItemNumber; index++) {
            onRedo();
        }
    }

    return (

        <Menu selectable={false}>
            {
                undo.length > 0 && (
                    <Menu.ItemGroup title='Undo'>
                        {
                            allUndo.slice(-10).map((item, index) => index < 10 && (
                                <Menu.Item key={index + 1} onClick={onUndoClick}>
                                    {index + 1}
                                    .
                                    {item}
                                </Menu.Item>
                            ))
                        }
                    </Menu.ItemGroup>
                )
            }
            {
                redo.length > 0 && (
                    <Menu.ItemGroup title='Redo'>
                        {
                            allRedo.slice(-10).map((item, index) => index < 10 && (
                                <Menu.Item key={index + 10} onClick={onRedoClick}>
                                    {index + 1}
                                    .
                                    {item}
                                </Menu.Item>
                            ))
                        }
                    </Menu.ItemGroup>
                )
            }
        </Menu>

    );
}

export default HistoryMenu;
