// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnnotationState, CombinedState, JobStage } from 'reducers/interfaces';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Select from 'antd/lib/select';

import CVATTooltip from 'components/common/cvat-tooltip';
import { ReviewSummaryComponent } from 'components/task-page/job-list';
import { updateJobAsync } from 'actions/tasks-actions';

function JobListPanel(): JSX.Element {
    const {
        job: { instance: jobInstance },
    }: AnnotationState = useSelector((state: CombinedState) => state.annotation);
    const { stage } = jobInstance;

    const [, setCurrentStage] = useState<string>(stage);

    useEffect(() => setCurrentStage(stage), [jobInstance.stage]);

    const dispatch = useDispatch();

    function onJobUpdate(jInstance: any): void {
        // Avoid updating the stage to "acceptance" in the db until validation is OK.
        // The update is performed in cvat-ui/src/components/annotation-page/extra-block.tsx
        if (jInstance.stage !== 'acceptance') {
            dispatch(updateJobAsync(jInstance));
        }
    }

    return (
        <div className='cvat-annotation-header-job-list-panel'>
            <Select
                value={stage}
                onChange={(newValue: string) => {
                    jobInstance.stage = newValue;
                    onJobUpdate(jobInstance);
                    setCurrentStage(newValue);
                }}
            >
                <Select.Option value={JobStage.ANNOTATION}>{JobStage.ANNOTATION}</Select.Option>
                <Select.Option value={JobStage.REVIEW}>{JobStage.REVIEW}</Select.Option>
                <Select.Option value={JobStage.ACCEPTANCE}>{JobStage.ACCEPTANCE}</Select.Option>
                <Select.Option value={JobStage.PROCESSED}>{JobStage.PROCESSED}</Select.Option>
            </Select>
            <CVATTooltip title={<ReviewSummaryComponent jobInstance={jobInstance} />}>
                <QuestionCircleOutlined />
            </CVATTooltip>
        </div>
    );
}

export default React.memo(JobListPanel);
