// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import { ActionUnion, createAction } from 'utils/redux';

export enum ControlPanelActionTypes {
    TOGGLE_CONTROL_PANEL = 'TOGGLE_CONTROL_PANEL',
    SHOW_AUTOPAUSE_MODE = 'SHOW_AUTOPAUSE_MODE',
}

export const controlPanelActions = {
    toggleControlPanel: () => createAction(ControlPanelActionTypes.TOGGLE_CONTROL_PANEL),
    showAutopauseMode: (isAutopauseMode: boolean) => createAction(ControlPanelActionTypes.SHOW_AUTOPAUSE_MODE, {
        isAutopauseMode,
    }),
};

export type ControlPanelActions = ActionUnion<typeof controlPanelActions>;
