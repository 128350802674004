// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Icon from '@ant-design/icons';

import { EyeInvisibleIcon, EyeOpenIcon } from 'icons';
import CVATTooltip from 'components/common/cvat-tooltip';
import { switchView } from 'actions/annotation-actions';
import { useDispatch } from 'react-redux';
import { ViewState } from 'reducers/interfaces';

export interface Props {
    switchHiddenAllShortcut: string;
    statesHidden: boolean;
    objectStates: any[];
    updateAnnotations(states: any[]): void;
    view: keyof typeof ViewState;
}

function ViewControl(props: Props): JSX.Element {
    const {
        switchHiddenAllShortcut, statesHidden, objectStates, view,
    } = props;
    const dispatch = useDispatch();

    const onSwitchView = (): void => {
        dispatch(switchView(view, objectStates));
    };

    return (
        <CVATTooltip title={`Switch hidden property for all ${switchHiddenAllShortcut}`} placement='right'>
            <Icon
                className='cvat-view-control'
                onClick={onSwitchView}
                component={statesHidden ? EyeInvisibleIcon : EyeOpenIcon}
            />
        </CVATTooltip>
    );
}

export default React.memo(ViewControl);
