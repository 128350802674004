// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import { ActionUnion, createAction } from 'utils/redux';

export enum EventsActionTypes {
    TOGGLE_EVENTS_MODE = 'TOGGLE_EVENTS_MODE',
}

export const eventsActions = {
    toggleEventsMode: () => createAction(EventsActionTypes.TOGGLE_EVENTS_MODE),
};

export type EventsActions = ActionUnion<typeof eventsActions>;
