// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable prefer-rest-params */
import Mousetrap, { MousetrapStatic } from 'mousetrap';

interface MousetrapRecord extends MousetrapStatic {
    record: Function;
    handleKey: Function;
    finishRecording: Function;
}

function getMousetrapRecord(MousetrapProp: MousetrapStatic): MousetrapRecord {
    let $recordedSequence: any[] = [];
    let $recordedSequenceCallback: Function | null = null;
    let $currentRecordedKeys: any[] = [];
    let $recordedCharacterKey = false;
    const $origHandleKey: Function = MousetrapProp.prototype.handleKey;

    function $normalizeSequence(sequence: any[]): void {
        let i;

        for (i = 0; i < sequence.length; ++i) {
            sequence[i].sort((x: any[], y: any[]) => {
                if (x.length > 1 && y.length === 1) {
                    return -1;
                } if (x.length === 1 && y.length > 1) {
                    return 1;
                }

                return x > y ? 1 : -1;
            });

            sequence[i] = sequence[i].join('+');
        }
    }

    function $finishRecording(): void {
        if ($recordedSequenceCallback) {
            $normalizeSequence($recordedSequence);
            $recordedSequenceCallback($recordedSequence);
        }

        $recordedSequence = [];
        $recordedSequenceCallback = null;
        $currentRecordedKeys = [];
    }

    function $recordCurrentCombo(): void {
        $recordedSequence.push($currentRecordedKeys);
        $currentRecordedKeys = [];
        $recordedCharacterKey = false;
        $finishRecording();
    }

    function $recordKey(key: string): void {
        let i;

        for (i = 0; i < $currentRecordedKeys.length; ++i) {
            if ($currentRecordedKeys[i] === key) {
                return;
            }
        }

        $currentRecordedKeys.push(key);

        if (key.length === 1) {
            $recordedCharacterKey = true;
        }
    }

    function $handleKey(this: any, character: string, modifiers: any[], e: Event): void {
        const self = this;

        if (!self.recording) {
            $origHandleKey.apply(self, arguments);
            return;
        }

        if (e.type === 'keydown') {
            if (character.length === 1 && $recordedCharacterKey) {
                $recordCurrentCombo();
            }

            for (let i = 0; i < modifiers.length; ++i) {
                $recordKey(modifiers[i]);
            }
            $recordKey(character);
        } else if (e.type === 'keyup' && $currentRecordedKeys.length > 0) {
            $recordCurrentCombo();
        }
    }

    MousetrapProp.prototype.record = function (callback: Function): void {
        const self = this;
        self.recording = true;
        $recordedSequenceCallback = function () {
            self.recording = false;
            callback.apply(self, arguments);
        };
    };

    MousetrapProp.prototype.handleKey = function () {
        const self = this;
        $handleKey.apply(self, arguments as any);
    };

    MousetrapProp.prototype.finishRecording = $finishRecording;

    MousetrapProp.init();

    return MousetrapProp as MousetrapRecord;
}

export default getMousetrapRecord(Mousetrap);
