// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Text from 'antd/lib/typography/Text';
import Radio, { RadioChangeEvent } from 'antd/lib/radio';
import Collapse from 'antd/lib/collapse';
import Button from 'antd/lib/button';
import { CombinedState } from 'reducers/interfaces';
import { switchPlay, multipleGroupsAnnotationsAsync } from 'actions/annotation-actions';
import { controlPanelActions } from 'actions/control-panel-actions';
import {
    getDiffereceBetweeenSumOfArrays,
    findTargetInLabel,
} from 'utils/helpers';

interface StateToProps {
    states: Array<any>;
    jobInstance: any;
    frame: number;
    isAutopauseMode: boolean;
}

interface DispatchToProps {
    onSwitchPlay(playing: boolean): void;
    onMultipleGroupAnnotations(
        sessionInstance: any,
        frame: number,
        states: any[],
        disableHistory?: boolean,
        index?: number,
    ): void;
    onShowAutopauseMode(isAutopauseMode: boolean): void;
}

interface Team {
    readonly label: string;
    readonly tracked: number;
    readonly expected: number;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const {
        annotation: {
            annotations: { states },
            job: { instance: jobInstance },
            player: { frame: { number: frame } },
        },
        isShowControlPanel: { isAutopauseMode },
    } = state;

    return {
        states,
        jobInstance,
        frame,
        isAutopauseMode,
    };
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        onSwitchPlay(playing: boolean): void {
            dispatch(switchPlay(playing));
        },
        onMultipleGroupAnnotations(
            sessionInstance: any,
            frame: number,
            states: any[],
            disableHistory?: boolean,
            index?: number,
        ): void {
            dispatch(multipleGroupsAnnotationsAsync(sessionInstance, frame, states, disableHistory, index));
        },
        onShowAutopauseMode(isAutopauseMode: boolean): void {
            dispatch(controlPanelActions.showAutopauseMode(isAutopauseMode));
        },
    };
}

type Props = StateToProps & DispatchToProps;

const TEAMS: Team[] = [
    {
        label: 'Team 1',
        tracked: 0,
        expected: 11,
    },
    {
        label: 'Team 2',
        tracked: 0,
        expected: 11,
    },
    {
        label: 'Referee',
        tracked: 0,
        expected: 1,
    },
];

function TrajectoriesBlock(props: Props): JSX.Element {
    const {
        states,
        jobInstance,
        frame,
        isAutopauseMode,
        onSwitchPlay,
        onMultipleGroupAnnotations,
        onShowAutopauseMode,
    } = props;

    const [collapsed, setCollapse] = useState<boolean>(false);
    const [selectedTeam, setSelectedTeam] = useState<number>(0);
    const [tracked, setTracked] = useState<number[]>(TEAMS.map((team) => team.tracked));
    const [expected, setExpected] = useState<number[]>(TEAMS.map((team) => team.expected));
    const [trajectories, setTrajectories] = useState<number>(0);
    const [stateCache, setStateCache] = useState<string[]>([]);
    const newTeams: string[] = [
        jobInstance.labels.map(
            (label) => findTargetInLabel(label.name)?.team,
        ).filter((e) => typeof e === 'string')[0],
    ];

    // update stateCache if we have new states with updated labels
    useEffect(() => {
        const newStateCache: string[] = states.map((state) => state.label.name);
        const isDifferentStateLength = stateCache.length !== newStateCache.length;
        if (isDifferentStateLength) {
            setStateCache(newStateCache);
        } else {
            const isDifferentState = newStateCache
                .some((label, idx) => label !== stateCache[idx]);
            if (isDifferentState) {
                setStateCache(newStateCache);
            }
        }
    }, [states]);

    // update tracked and groups if stateCache is updated
    useEffect(() => {
        const newTracked: number[] = TEAMS.map((team) => team.tracked);
        const groups: any[] = [[], [], []];
        states.forEach((state) => {
            const labelName = state.label.name;
            const target = findTargetInLabel(labelName);
            if (target?.team) {
                const trackedTeamIndex: number = newTeams.findIndex((team) => team === target.team);
                if (trackedTeamIndex !== -1) {
                    ++newTracked[trackedTeamIndex];
                    groups[trackedTeamIndex].push(state);
                } else {
                    const newTeamIndex: number = ((trackedTeamIndex === -1) && (newTeams.length < 2)) ?
                        newTeams.length : -1;
                    if (newTeamIndex !== -1) {
                        newTeams.push(target.team as string);
                        ++newTracked[newTeamIndex];
                        groups[newTeamIndex].push(state);
                    }
                }
            } else if (target?.referee) {
                ++newTracked[2];
                groups[2].push(state);
            }
        });
        setTracked(newTracked);
        // disable history while auto groupping
        const disableHistory = true;
        const index = 1000004;
        onMultipleGroupAnnotations(jobInstance, frame, groups, disableHistory, index);
        const difTrajectories = getDiffereceBetweeenSumOfArrays(expected, newTracked);
        if (difTrajectories > 0) {
            onSwitchPlay(false);
        }
    }, [stateCache]);

    useEffect(() => {
        const difTrajectories = getDiffereceBetweeenSumOfArrays(expected, tracked);
        setTrajectories(difTrajectories > 0 ? difTrajectories : 0);
        if (difTrajectories > 0) {
            if (!isAutopauseMode) {
                onShowAutopauseMode(true);
            }
        } else if (isAutopauseMode) {
            onShowAutopauseMode(false);
        }
    }, [tracked, expected]);

    const handleChangeSelectedTeam = (event: RadioChangeEvent): void => setSelectedTeam(event.target.value);
    const incrementExpected = (teamIndex: number): void => setExpected((prev) => {
        const copy = [...prev];
        ++copy[teamIndex];
        return copy;
    });
    const decrementExpected = (teamIndex: number): void => {
        if (expected[teamIndex] > 0) {
            setExpected((prev) => {
                const copy = [...prev];
                --copy[teamIndex];
                return copy;
            });
        }
    };

    return (
        <Collapse
            onChange={() => setCollapse((prev) => !prev)}
            activeKey={collapsed ? [] : ['trajectories']}
            className='cvat-objects-appearance-collapse'
        >
            <Collapse.Panel
                header={(
                    <Text strong>
                        Trajectories
                    </Text>
                )}
                key='trajectories'
            >
                <div className='cvat-objects-appearance-content'>
                    <Text type={trajectories ? 'danger' : 'secondary'}>
                        Unassigned Trajectories:
                        {' '}
                        {trajectories}
                    </Text>
                    <Radio.Group
                        className='cvat-appearance-color-by-radio-group cvat-objects-trajectories-by-radio-group'
                        onChange={handleChangeSelectedTeam}
                        value={selectedTeam}
                    >
                        {TEAMS.map(({ label }, idx) => (
                            <Radio.Button
                                value={idx}
                                key={`cvat-objects-trajectories-radio-button-${label}`}
                            >
                                {label}
                            </Radio.Button>
                        ))}
                    </Radio.Group>
                    <div className='cvat-objects-trajectories-buttons'>
                        <Button
                            onClick={() => decrementExpected(selectedTeam)}
                            disabled={expected[selectedTeam] < 1}
                        >
                            Ignore Player
                        </Button>
                        <Button onClick={() => incrementExpected(selectedTeam)}>
                            New Player
                        </Button>
                    </div>
                    <div className='cvat-objects-trajectories-grid'>
                        <div />
                        {TEAMS.map(({ label }, idx) => (
                            <Text
                                key={`cvat-objects-trajectories-grid-header-${idx}`}
                                strong
                            >
                                {label}
                            </Text>
                        ))}
                        <Text>Tracked Players</Text>
                        {tracked.map((value, idx) => (
                            <Text
                                key={`cvat-objects-trajectories-grid-tracked-${idx}`}
                                type={value !== expected[idx] ? 'danger' : undefined}
                                className='text-center'
                            >
                                {value}
                            </Text>
                        ))}
                        <Text>Expected Players</Text>
                        {expected.map((value, idx) => (
                            <Text
                                key={`cvat-objects-trajectories-grid-expected-${idx}`}
                                className='text-center'
                            >
                                {value}
                            </Text>
                        ))}
                    </div>
                </div>
            </Collapse.Panel>
        </Collapse>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(TrajectoriesBlock));
