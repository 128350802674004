// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
import { Events, EventsConstant } from 'reducers/interfaces';

export const DEFAULT_EVENTS = [
    {
        name: Events.NOT_CORRECTED_END,
        attributes: [],
    },
    {
        name: Events.NOT_CORRECTED_START,
        attributes: [],
    },
    {
        name: Events.OTHER,
        attributes: [],
    },
    {
        name: Events.END_OF_PLAY,
        attributes: [],
    },
    {
        name: Events.CORNER_KICK,
        attributes: [],
    },
    {
        name: Events.THROW_IN,
        attributes: [],
    },
    {
        name: Events.GOAL_KICK,
        attributes: [],
    },
    {
        name: Events.OUT,
        attributes: [],
    },
    {
        name: Events.OFFSIDE,
        attributes: [],
    },
    {
        name: Events.FOUL,
        attributes: [],
    },
    {
        name: Events.STOP,
        attributes: [],
    },
    {
        name: Events.PENALTY_KICK,
        attributes: [],
    },
    {
        name: Events.DROP_BALL,
        attributes: [],
    },
    {
        name: Events.GOAL_CHANCE,
        attributes: [],
    },
    {
        name: Events.GOAL,
        attributes: [
            {
                name: 'player_id',
                mutable: true,
                input_type: 'text',
                default_value: '',
                values: [
                    '',
                ],
            },
        ],
    },
    {
        name: Events.VAR,
        attributes: [
            {
                name: 'var_frame',
                mutable: true,
                input_type: 'text',
                default_value: '',
                values: [
                    '',
                ],
            },
        ],
    },
    {
        name: Events.CHANGE_PLAYER_POSITION,
        attributes: [
            {
                name: 'position',
                mutable: true,
                input_type: 'text',
                default_value: '',
                values: [
                    '',
                ],
            },
            {
                name: 'player_id',
                mutable: true,
                input_type: 'text',
                default_value: '',
                values: [
                    '',
                ],
            },
        ],
    },
    {
        name: Events.PLAYER_IN,
        attributes: [
            {
                name: 'position',
                mutable: true,
                input_type: 'text',
                default_value: '',
                values: [
                    '',
                ],
            },
            {
                name: 'player_id',
                mutable: true,
                input_type: 'text',
                default_value: '',
                values: [
                    '',
                ],
            },
        ],
    },
    {
        name: Events.PLAYER_OUT,
        attributes: [
            {
                name: 'player_id',
                mutable: true,
                input_type: 'text',
                default_value: '',
                values: [
                    '',
                ],
            },
        ],
    },
    {
        name: Events.RED_CARD,
        attributes: [
            {
                name: 'player_id',
                mutable: true,
                input_type: 'text',
                default_value: '',
                values: [
                    '',
                ],
            },
        ],
    },
    {
        name: Events.YELLOW_CARD,
        attributes: [
            {
                name: 'player_id',
                mutable: true,
                input_type: 'text',
                default_value: '',
                values: [
                    '',
                ],
            },
        ],
    },
    {
        name: Events.SHOT,
        attributes: [
            {
                name: 'player_id',
                mutable: true,
                input_type: 'text',
                default_value: '',
                values: [
                    '',
                ],
            },
        ],
    },
    {
        name: Events.INTERCEPTION,
        attributes: [
            {
                name: 'player_id',
                mutable: true,
                input_type: 'text',
                default_value: '',
                values: [
                    '',
                ],
            },
        ],
    },
    {
        name: Events.RECEPTION,
        attributes: [
            {
                name: 'player_id',
                mutable: true,
                input_type: 'text',
                default_value: '',
                values: [
                    '',
                ],
            },
        ],
    },
    {
        name: Events.PASS,
        attributes: [
            {
                name: 'player_id',
                mutable: true,
                input_type: 'text',
                default_value: '',
                values: [
                    '',
                ],
            },
        ],
    },
    {
        name: Events.TEAM2,
        attributes: [],
    },
    {
        name: Events.TEAM1,
        attributes: [],
    },
    {
        name: Events.CHUNK_INFO,
        attributes: [
            {
                name: 'info',
                mutable: false,
                input_type: 'text',
                default_value: '',
                values: [
                    '',
                ],
            },
        ],
    },
];

export const DEFAULT_EVENT_KEYS: EventsConstant[] = [
    { name: Events.PASS, sequences: ['enter'] },
    { name: Events.RECEPTION, sequences: ['backspace'] },
    { name: Events.INTERCEPTION, sequences: ['p'] },
    { name: Events.SHOT, sequences: ['i'] },
    { name: Events.OFFSIDE, sequences: ['j'] },
    { name: Events.FOUL, sequences: ['h'] },
    { name: Events.STOP, sequences: ['f'] },
    { name: Events.PENALTY_KICK, sequences: ['s'] },
    { name: Events.DROP_BALL, sequences: ['a'] },
    { name: Events.OTHER, sequences: ['q'] },
    { name: Events.GOAL_KICK, sequences: ['insert'] },
    { name: Events.THROW_IN, sequences: ['home'] },
    { name: Events.OUT, sequences: ['end'] },
    { name: Events.CORNER_KICK, sequences: ['pageup'] },
    { name: Events.GOAL, sequences: ['f6'] },
    { name: Events.GOAL_CHANCE, sequences: ['f8'] },
    { name: Events.PLAYER_OUT, sequences: ['f9'] },
    { name: Events.PLAYER_IN, sequences: ['f10'] },
    { name: Events.YELLOW_CARD, sequences: ['f11'] },
    { name: Events.RED_CARD, sequences: ['f12'] },
    { name: Events.VAR, sequences: ['z'] },
    { name: Events.CHANGE_PLAYER_POSITION, sequences: ['w'] },
    { name: Events.END_OF_PLAY, sequences: ['k'] },
];
