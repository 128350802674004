// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
import { Attribute } from 'components/labels-editor/common';

export function findTargetInLabel(labelName: string): { team: string | boolean, referee: boolean } | null {
    const labelData = labelName.split('-');
    const isPlayer = labelData.length > 2;
    const isReferee = labelName.includes('Referee');
    if (isPlayer || isReferee) {
        return {
            team: isPlayer ? labelData[2] : false,
            referee: isReferee,
        };
    }
    return null;
}

export function getDiffereceBetweeenSumOfArrays(arr1: number[], arr2: number[]): number {
    const arr1Sum = arr1.reduce((acc, count) => acc + count, 0);
    const arr2Sum = arr2.reduce((acc, count) => acc + count, 0);
    const difSum = arr1Sum - arr2Sum;
    return difSum;
}

export function normalizeCamelCase(text: string): string {
    const result = text.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
}

export function getPlayerIdAttribute(state: any, jobAttributes: any): Attribute {
    const stateLabelId = state.label.id;
    const stateAttributes = jobAttributes[stateLabelId];
    return stateAttributes
        .find((attr: Attribute): boolean => attr.name === 'player_id');
}

export function getPlayerIdFromEventsLabel(label: string): string {
    const labelData = label.split(' (');
    return labelData[0];
}
