// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useEffect, useState } from 'react';
import Icon from '@ant-design/icons';

import { SwapIcon } from 'icons';
import { Canvas } from 'cvat-canvas-wrapper';
import { Canvas3d } from 'cvat-canvas3d-wrapper';
import { ActiveControl, DimensionType } from 'reducers/interfaces';
import CVATTooltip from 'components/common/cvat-tooltip';
import { useSelector } from 'react-redux';

export interface Props {
    canvasInstance: Canvas | Canvas3d;
    activeControl: ActiveControl;
    switchSwapShortcut: string;
    disabled?: boolean;
    jobInstance?: any;
    swapObjects(enabled: boolean): void;
}

function SwapControl(props: Props): JSX.Element {
    const {
        switchSwapShortcut,
        activeControl,
        canvasInstance,
        swapObjects,
        disabled,
        jobInstance,
    } = props;

    const dynamicIconProps =
        activeControl === ActiveControl.SWAP ?
            {
                className: 'cvat-swap-control cvat-active-canvas-control',
                onClick: (): void => {
                    canvasInstance.swap({ enabled: false });
                    swapObjects(false);
                },
            } :
            {
                className: 'cvat-swap-control',
                onClick: (): void => {
                    canvasInstance.cancel();
                    canvasInstance.swap({ enabled: true });
                    swapObjects(true);
                },
            };

    const title = [
        `Swap shapes${jobInstance && jobInstance.dimension === DimensionType.DIM_3D ? '' : '/tracks'
        } ${switchSwapShortcut}.`,
    ].join(' ');

    return disabled ? (
        <Icon className='cvat-swap-control cvat-disabled-canvas-control' component={SwapIcon} />
    ) : (
        <CVATTooltip title={title} placement='right'>
            <Icon {...dynamicIconProps} component={SwapIcon} />
        </CVATTooltip>
    );
}

export default React.memo(SwapControl);
