// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
// TODO: Add api call when BE will be added (get req to api/v1/jobs/${jobID}/validate)
// import getCore from 'cvat-core-wrapper';
import Text from 'antd/lib/typography/Text';
import Collapse from 'antd/lib/collapse';
import Button from 'antd/lib/button';
import { CombinedState } from 'reducers/interfaces';
import { splitAllAnnotationsAsync } from 'actions/annotation-actions';
import { SplitCellsOutlined } from '@ant-design/icons';
import CVATTooltip from 'components/common/cvat-tooltip';
import Modal from 'antd/lib/modal';
import Spin from 'antd/lib/spin';
import GlobalHotKeys, { KeyMap } from 'utils/mousetrap-react';
import { updateJobAsync } from 'actions/tasks-actions';
import serverProxy from '../../../../cvat-core/src/server-proxy';

interface StateToProps {
    states: Array<any>;
    jobInstance: any;
    frame: number;
    keyMap: KeyMap;
    normalizedKeyMap: Record<string, string>;
    stage: string;
}

interface DispatchToProps {
    onSplitAllAnnotations(sessionInstance: any, frame: number, states: any[]): void;
}

interface ValidationMessage {
    title: string;
    messages: Array<string>;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const {
        annotation: {
            player: { frame: { number: frame } },
            job: { instance: jobInstance },
            annotations: { states },
        },
        shortcuts: { keyMap, normalizedKeyMap },
    } = state;

    const {
        annotation: {
            job: { instance: { stage } },
        },
    } = state;

    return {
        jobInstance,
        states,
        frame,
        keyMap,
        normalizedKeyMap,
        stage,
    };
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        onSplitAllAnnotations(sessionInstance: any, frame: number, states: any[]): void {
            dispatch(splitAllAnnotationsAsync(sessionInstance, frame, states));
        },
    };
}

type Props = StateToProps & DispatchToProps;

function ExtraBlock(props: Props): JSX.Element {
    const {
        normalizedKeyMap,
        jobInstance,
        keyMap,
        states,
        frame,
        onSplitAllAnnotations,
        stage,
    } = props;
    const dispatch = useDispatch();
    const [collapsed, setCollapse] = useState<boolean>(false);

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isValidationSuccess, setIsValidationSuccess] = useState(null);
    const [validationData, setValidationData] = useState<ValidationMessage[] | null>(null);
    const { requestedId } = useSelector((state) => state.annotation.job);
    const handleOk = (): void => {
        setIsModalVisible(false);
        setIsValidationSuccess(null);
        setValidationData(null);
    };
    const { isEventMode } = useSelector((state: CombinedState) => state.events);

    const validateDuplicates = (duplicateData: any): ValidationMessage | null => {
        if (!duplicateData?.length) return null;
        const validationMessage: ValidationMessage = {
            title: 'Duplicates:',
            messages: [],
        };
        for (const data of duplicateData) {
            const name = data.label_name;
            const startFrame = data.start_frame;
            const endFrame = data.end_frame;

            const message = `- ${name}: ${startFrame} - ${endFrame}`;
            validationMessage.messages.push(message);
        }
        return validationMessage;
    };

    const validateThirdPartyUnknown = (unknownData: any): ValidationMessage | null => {
        if (!unknownData?.length) return null;
        const validationMessage: ValidationMessage = {
            title: 'Frames with trajectories that need to be identified:',
            messages: [],
        };
        for (const data of unknownData) {
            const startFrame = data.start_frame;
            const endFrame = data.end_frame;

            const message = `- ${startFrame} - ${endFrame}`;
            validationMessage.messages.push(message);
        }
        return validationMessage;
    };

    const validateNotCorrected = (notCorrectedData: any): ValidationMessage | null => {
        if (notCorrectedData.number || notCorrectedData.order) {
            const validationMessage: ValidationMessage = {
                title: 'Not Corrected:',
                messages: [],
            };
            const startEvents = notCorrectedData.events.start;
            const endEvents = notCorrectedData.events.end;
            if (notCorrectedData.number) {
                const numStart = notCorrectedData.number.start;
                const numEnd = notCorrectedData.number.end;
                const startMessage = `- Num. Start: ${numStart} (${startEvents})`;
                const endMessage = `- Num. End: ${numEnd} (${endEvents})`;
                validationMessage.messages.push(startMessage, endMessage);
            } else {
                const startFrame = notCorrectedData.order.start;
                const endFrame = notCorrectedData.order.end;
                const message = `End (${endFrame}) cannot be lower than Start (${startFrame})`;
                validationMessage.messages.push(message);
            }
            return validationMessage;
        }
        return null;
    };

    const validateEvents = (eventsData: any): ValidationMessage | null => {
        if (eventsData?.player_id?.length > 0 || eventsData?.same_frame?.length > 0) {
            const validationMessage: ValidationMessage = {
                title: 'Events:',
                messages: [],
            };
            for (const event of eventsData.player_id) {
                const eventFrame = event.frame;
                const eventType = event.event_type;
                const eventPlayerId = event.player_id;
                const message = `- Frame ${eventFrame}: ${eventPlayerId} is not in the game (event ${eventType})`;
                validationMessage.messages.push(message);
            }
            for (const eventFrame of eventsData.same_frame) {
                const message = `- Frame ${eventFrame}: Two events in the same frame`;
                validationMessage.messages.push(message);
            }
            return validationMessage;
        }
        return null;
    };

    const onValidationClick = async (): Promise<void> => {
        setIsModalVisible(true);
        const result = await serverProxy.jobs.getValidate(requestedId);

        setIsValidationSuccess(result.ok);
        // setValidationData(JSON.stringify(result.not_corrected));
        if (!result.ok) {
            jobInstance.stage = 'annotation';
            dispatch(updateJobAsync(jobInstance));

            const duplicatesMessages = validateDuplicates(result.trajectories.duplicates);
            const notCorrectedMessages = validateNotCorrected(result.not_corrected);
            const eventsMessages = validateEvents(result.events);
            const thirdPartyUnknownMessages = validateThirdPartyUnknown(
                result.trajectories.third_party_unknown,
            );

            const totalMessage = [
                duplicatesMessages, notCorrectedMessages, eventsMessages, thirdPartyUnknownMessages,
            ].filter((msgs) => msgs);
            setValidationData(totalMessage as ValidationMessage[]);
        } else {
            dispatch(updateJobAsync(jobInstance));
        }
    };

    useEffect(() => {
        if (stage === 'acceptance') {
            onValidationClick();
        }
    }, [stage]);

    const onSplitAll = (): void => {
        onSplitAllAnnotations(jobInstance, frame, states);
    };

    const preventDefault = (event: KeyboardEvent | undefined): void => {
        if (event) {
            event.preventDefault();
        }
    };

    const subKeyMap = {
        SPLIT_ALL: keyMap.SPLIT_ALL,
    };

    const handlers = {
        SPLIT_ALL: (event: KeyboardEvent | undefined) => {
            preventDefault(event);
            onSplitAll();
        },
    };

    return (
        <Collapse
            onChange={() => setCollapse((prev) => !prev)}
            activeKey={collapsed ? [] : ['extra']}
            className='cvat-objects-appearance-collapse'
        >
            <Collapse.Panel
                header={(
                    <Text strong>
                        Extra
                    </Text>
                )}
                key='extra'
            >
                <GlobalHotKeys keyMap={subKeyMap} handlers={handlers} />
                <div className='cvat-objects-appearance-content'>
                    {!isEventMode && (
                        <>
                            <Text type='secondary'>Extra features</Text>
                            <div className='cvat-objects-extra-buttons'>
                                <CVATTooltip title={`Split all the shapes in the frame ${normalizedKeyMap.SPLIT_ALL}`} placement='left'>
                                    <Button
                                        icon={<SplitCellsOutlined />}
                                        onClick={onSplitAll}
                                        type='primary'
                                    >
                                        Split all
                                    </Button>
                                </CVATTooltip>
                            </div>
                        </>
                    )}
                    <Text strong>Task Validation</Text>
                    <div className='cvat-objects-appearance-content'>
                        <Button block onClick={onValidationClick}>
                            Start Task Validation
                        </Button>
                        <Modal
                            cancelButtonProps={{ style: { display: 'none' } }}
                            visible={isModalVisible}
                            title='Task Validation'
                            onCancel={handleOk}
                            onOk={handleOk}
                        >
                            {isValidationSuccess === null ? (
                                <div className='cvat-task-validation-modal-loader-container'>
                                    <Spin tip='Loading...' />
                                </div>
                            ) : (
                                <div>
                                    {isValidationSuccess ?
                                        <p>Validation is passed successfully.</p> : (
                                            <div>
                                                <p>Validation failed.</p>
                                                {validationData?.length ? validationData
                                                    .map(({ title, messages }, idx) => (
                                                        <div key={`valid-data-${title}-${idx}`}>
                                                            <div>
                                                                <b>{title}</b>
                                                            </div>
                                                            {messages.map((msg, mIdx) => (
                                                                <div key={`valid-data-message-${title}-${idx}-${mIdx}`}>
                                                                    {msg}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )) : null}
                                            </div>
                                        )}
                                </div>
                            )}
                        </Modal>
                    </div>
                </div>
            </Collapse.Panel>
        </Collapse>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ExtraBlock));
