import React from 'react';
import Icon from '@ant-design/icons';

import { CutIcon } from 'icons';
import { Canvas } from 'cvat-canvas-wrapper';
import { ActiveControl } from 'reducers/interfaces';
import CVATTooltip from 'components/common/cvat-tooltip';

export interface Props {
  canvasInstance: Canvas;
  activeControl: ActiveControl;
  switchCutShortcut: string;
  disabled?: boolean;
  cutObjects(enabled: boolean): void;
}

function CutControl(props: Props): JSX.Element {
  const {
    switchCutShortcut, activeControl, canvasInstance, cutObjects, disabled,
  } = props;

  const dynamicIconProps =
    activeControl === ActiveControl.CUT ?
      {
        className: 'cvat-cut-control cvat-active-canvas-control',
        onClick: (): void => {
          canvasInstance.cut({ enabled: false });
          cutObjects(false);
        },
      } :
      {
        className: 'cvat-cut-control',
        onClick: (): void => {
          canvasInstance.cancel();
          canvasInstance.cut({ enabled: true });
          cutObjects(true);
        },
      };

  return disabled ? (
    <Icon className='cvat-cut-control cvat-disabled-canvas-control' component={CutIcon} />
  ) : (
    <CVATTooltip title={`Cut shapes/tracks ${switchCutShortcut}`} placement='right'>
      <Icon {...dynamicIconProps} component={CutIcon} />
    </CVATTooltip>
  );
}

export default React.memo(CutControl);