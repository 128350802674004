// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import { EventsActions, EventsActionTypes } from 'actions/events-actions';

import { EventsState } from './interfaces';

const defaultState: EventsState = {
    isEventMode: false,
};

export default (state: EventsState = defaultState, action: EventsActions): EventsState => {
    switch (action.type) {
        case EventsActionTypes.TOGGLE_EVENTS_MODE:
            return {
                ...state,
                isEventMode: !state.isEventMode,
            };
        default:
            return state;
    }
};
