// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import * as SVG from 'svg.js';
import { CutData } from './canvasModel';

export interface CutHandler {
    cut(cutData: CutData): void;
    select(state: any): void;
    cancel(): void;
    repeatSelection(): void;
}

export class CutHandlerImpl implements CutHandler {
    // callback is used to notify about cutting end
    private onCutDone: (objects: any[] | null, duration?: number) => void;
    private onFindObject: (event: MouseEvent) => void;
    private startTimestamp: number;
    private canvas: SVG.Container;
    private initialized: boolean;
    private statesToBeCutted: any[]; // are being cutted
    private highlightedShapes: Record<number, SVG.Shape>;
    private constraints: {
        labelID: number;
        shapeType: string;
    };

    private addConstraints(): void {
        const shape = this.statesToBeCutted[0];
        this.constraints = {
            labelID: shape.label.id,
            shapeType: shape.shapeType,
        };
    }

    private removeConstraints(): void {
        this.constraints = null;
    }

    private checkConstraints(state: any): boolean {
        return (
            !this.constraints ||
      (state.shapeType === this.constraints.shapeType)
        );
    }

    private release(): void {
        this.removeConstraints();
        this.canvas.node.removeEventListener('click', this.onFindObject);
        for (const state of this.statesToBeCutted) {
            const shape = this.highlightedShapes[state.clientID];
            shape.removeClass('cvat_canvas_shape_cutting');
            shape.removeClass('cvat_canvas_shape_cutting_double');
        }
        this.statesToBeCutted = [];
        this.highlightedShapes = {};
        this.initialized = false;
    }

    private initCutting(): void {
        this.canvas.node.addEventListener('click', this.onFindObject);
        this.startTimestamp = Date.now();
        this.initialized = true;
    }

    private closeCutting(): void {
        if (this.initialized) {
            const { statesToBeCutted } = this;
            this.release();

            if (statesToBeCutted.length > 1) {
                this.onCutDone(statesToBeCutted, Date.now() - this.startTimestamp);
            } else {
                this.onCutDone(null);
                // here is a cycle
                // onCutDone => controller => model => view => closeCutting
                // one call of closeCutting is unuseful, but it's okey
            }
        }
    }

    public constructor(
        onCutDone: (objects: any[] | null, duration?: number) => void,
        onFindObject: (event: MouseEvent) => void,
        canvas: SVG.Container,
    ) {
        this.onCutDone = onCutDone;
        this.onFindObject = onFindObject;
        this.startTimestamp = Date.now();
        this.canvas = canvas;
        this.statesToBeCutted = [];
        this.highlightedShapes = {};
        this.constraints = null;
        this.initialized = false;
    }

    public cut(cutData: CutData): void {
        if (cutData.enabled) {
            this.initCutting();
        } else {
            this.closeCutting();
        }
    }

    public select(objectState: any): void {
        const sameShapes = this.statesToBeCutted
            .filter((state): boolean => state.clientID === objectState.clientID);
        const isEvenShapes = sameShapes.length % 2 === 0;

        const isSameShapeAndFrame = this.statesToBeCutted
            .findIndex((state): boolean => (state.frame === objectState.frame) &&
            (state.clientID === objectState.clientID));

        const toBeCuttedLength = this.statesToBeCutted.length;

        if (isSameShapeAndFrame !== -1) {
            const shape = this.highlightedShapes[objectState.clientID];
            this.statesToBeCutted.splice(isSameShapeAndFrame, 1);
            if (shape) {
                delete this.highlightedShapes[objectState.clientID];

                if (sameShapes.length === 1) {
                    shape.removeClass('cvat_canvas_shape_cutting');
                    shape.removeClass('cvat_canvas_shape_cutting_double');
                } else if (isEvenShapes) {
                    shape.removeClass('cvat_canvas_shape_cutting_double');
                    shape.addClass('cvat_canvas_shape_cutting');
                } else {
                    shape.removeClass('cvat_canvas_shape_cutting');
                    shape.addClass('cvat_canvas_shape_cutting_double');
                }
            }

            if (!toBeCuttedLength) {
                this.removeConstraints();
            }
        } else {
            const shape = this.canvas.select(`#cvat_canvas_shape_${objectState.clientID}`).first();
            if (shape && this.checkConstraints(objectState)) {
                this.statesToBeCutted.push(objectState);
                this.highlightedShapes[objectState.clientID] = shape;

                if (isEvenShapes) {
                    shape.addClass('cvat_canvas_shape_cutting');
                    shape.removeClass('cvat_canvas_shape_cutting_double');
                } else {
                    shape.addClass('cvat_canvas_shape_cutting_double');
                    shape.removeClass('cvat_canvas_shape_cutting');
                }

                if (toBeCuttedLength === 1) {
                    this.addConstraints();
                }
            }
        }
    }

    public repeatSelection(): void {
        for (const objectState of this.statesToBeCutted) {
            const shape = this.canvas.select(`#cvat_canvas_shape_${objectState.clientID}`).first();
            if (shape) {
                this.highlightedShapes[objectState.clientID] = shape;
                shape.addClass('cvat_canvas_shape_cutting');
            }
        }
    }

    public cancel(): void {
        this.release();
        this.onCutDone(null);
        // here is a cycle
        // onCutDone => controller => model => view => closeCutting
        // one call of closeCutting is unuseful, but it's okey
    }
}
