// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Text from 'antd/lib/typography/Text';
import Collapse from 'antd/lib/collapse';
import Button from 'antd/lib/button';
import { CombinedState, DimensionType, AnnotationState } from 'reducers/interfaces';
import CVATTooltip from 'components/common/cvat-tooltip';
import GlobalHotKeys, { KeyMap, KeyMapItem } from 'utils/mousetrap-react';
import { formatShortcuts } from 'reducers/shortcuts-reducer';
import { getPlayerIdAttribute, normalizeCamelCase } from 'utils/helpers';
import { LogType } from 'cvat-logger';
import { updateAnnotationsAsync } from 'actions/annotation-actions';

interface EventsButton {
    name: string;
    title: string;
    onClick: any;
    disabled: boolean;
}

interface EventsButtonMap {
    [index: string]: EventsButton;
}

interface EventsMap {
    eventsButtonMap: EventsButtonMap;
    eventsKeyMap: KeyMap;
    eventsHandlers: any;
}

interface SpecialPlayerIdKey {
    sequences: KeyMapItem['sequences'];
    value: string;
    action?: KeyMapItem['action'];
}

function EventsBlock(): JSX.Element {
    const {
        job: { labels, attributes: jobAttributes, instance: jobInstance },
        canvas: { instance: canvasInstance },
        annotations: { states },
    }: AnnotationState = useSelector((state: CombinedState) => state.annotation);
    const { eventKeys } = useSelector((state: CombinedState) => state.settings.events);
    const dispatch = useDispatch();

    const [collapsed, setCollapse] = useState<boolean>(false);

    const SPECIAL_PLAYER_ID_KEYS: SpecialPlayerIdKey[] = [
        { value: 'Team1', sequences: ['*'], action: 'keypress' },
        { value: 'Team2', sequences: ['-'] },
    ];

    function updateState(state: any): void {
        dispatch(updateAnnotationsAsync([state]));
    }

    function onDrawEvent(labelName: string): void {
        const targetLabel = labels.find((label: any) => label.name === labelName);
        if (targetLabel) canvasInstance?.drawGhostBox({ label: targetLabel });
    }

    function onKeyDrawEvent(event: KeyboardEvent | undefined, labelName: string): void {
        if (event) event.preventDefault();
        onDrawEvent(labelName);
    }

    function onKeyChangeAttribute(event: KeyboardEvent | undefined, value: string, replace?: boolean): void {
        if (event) event.preventDefault();
        if (!states.length) return;

        const objectState = states[states.length - 1];
        const playerIdAttribute = getPlayerIdAttribute(objectState, jobAttributes);
        if (!playerIdAttribute) return;

        const playerIdAttributeId = playerIdAttribute.id;
        const playerIdAttributeNewValue = replace ? value :
            objectState.attributes[playerIdAttributeId] + value;

        jobInstance.logger.log(LogType.changeAttribute, {
            id: playerIdAttributeId,
            value: playerIdAttributeNewValue,
            object_id: objectState.clientID,
        });

        const attr: Record<number, string> = {};
        attr[playerIdAttributeId] = playerIdAttributeNewValue;
        objectState.attributes = attr;
        updateState(objectState);
    }

    const {
        eventsButtonMap,
        eventsHandlers,
        eventsKeyMap,
    }: EventsMap = useMemo(() => {
        const eventsMapData = eventKeys.reduce((acc: EventsMap, { name, sequences }) => {
            const normalizedName = normalizeCamelCase(name);
            const eventsKey: KeyMapItem = {
                name: normalizedName,
                description: `Add "${normalizedName}" event to the current frame`,
                sequences,
                action: 'keydown',
                applicable: [DimensionType.DIM_2D],
            };
            const eventsButton: EventsButton = {
                name,
                title: `Add ${normalizedName} event to the current frame  ${formatShortcuts(eventsKey)}`,
                onClick: () => onDrawEvent(name),
                disabled: labels.every((label: any) => label.name !== name),
            };
            const handler = (event: KeyboardEvent | undefined): void => onKeyDrawEvent(event, name);
            acc.eventsButtonMap[name] = eventsButton;
            acc.eventsHandlers[name] = handler;
            acc.eventsKeyMap[name] = eventsKey;
            return acc;
        }, {
            eventsButtonMap: {},
            eventsHandlers: {},
            eventsKeyMap: {},
        });

        for (let i = 0; i < 10; i++) {
            const key = `attributeControl${i}`;
            const name = `Attribute control button ${i}`;
            const eventsKey: KeyMapItem = {
                name,
                description: `Update player_id in the last event [${i}]`,
                sequences: [i.toString()],
                action: 'keydown',
                applicable: [DimensionType.DIM_2D],
            };
            const handler = (event: KeyboardEvent | undefined): void => {
                onKeyChangeAttribute(event, i.toString());
            };
            eventsMapData.eventsHandlers[key] = handler;
            eventsMapData.eventsKeyMap[key] = eventsKey;
        }

        SPECIAL_PLAYER_ID_KEYS.forEach(({ sequences, value, action = 'keydown' }) => {
            const key = `attributeSpecialControl${value}`;
            const name = `Attribute special control button ${value}`;
            const eventsKey: KeyMapItem = {
                name,
                description: `Update player_id in the last event with special value - ${value}`,
                sequences,
                action,
                applicable: [DimensionType.DIM_2D],
            };
            const handler = (event: KeyboardEvent | undefined): void => {
                onKeyChangeAttribute(event, value, true);
            };
            eventsMapData.eventsHandlers[key] = handler;
            eventsMapData.eventsKeyMap[key] = eventsKey;
        });

        return eventsMapData;
    }, [states]);

    return (
        <Collapse
            onChange={() => setCollapse((prev) => !prev)}
            activeKey={collapsed ? [] : ['events']}
            className='cvat-objects-appearance-collapse'
        >
            <Collapse.Panel
                header={(
                    <Text strong>
                        Events
                    </Text>
                )}
                key='events'
            >
                <GlobalHotKeys keyMap={eventsKeyMap} handlers={eventsHandlers} />
                <div className='cvat-objects-appearance-content'>
                    <div className='cvat-objects-events-buttons'>
                        {Object.keys(eventsButtonMap).map((key: string, idx: number) => {
                            const eventsButton: EventsButton = eventsButtonMap[key];
                            return (
                                <CVATTooltip
                                    key={`cvat-objects-events-button-${idx}`}
                                    title={eventsButton.title}
                                    placement='left'
                                >
                                    <Button
                                        disabled={eventsButton.disabled}
                                        onClick={eventsButton.onClick}
                                        block
                                    >
                                        {normalizeCamelCase(eventsButton.name)}
                                    </Button>
                                </CVATTooltip>
                            );
                        })}
                    </div>
                </div>
            </Collapse.Panel>
        </Collapse>
    );
}

export default React.memo(EventsBlock);
