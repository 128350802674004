import { StartMatchTimeTypes } from "actions/start-match-button-action";
import { StartMatchTime } from "./interfaces";

const defaultState: StartMatchTime = {
    time: null,
}

export default (state: StartMatchTime = defaultState, action: StartMatchTimeTypes): StartMatchTime => {
    switch (action.type) {
        case StartMatchTimeTypes.START_MATCH_TIME:
            return {
                ...state,
                time: action.payload
            }
        default:
            return state
    }
}