// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnnotationState, CombinedState, Events } from 'reducers/interfaces';
import Button from 'antd/lib/button';
import { removeObjectAsync } from 'actions/annotation-actions';

interface Status {
    [key: string]: boolean;
}

interface NotCorrectedLabels {
    [key: string]: any;
}

const { NOT_CORRECTED_START, NOT_CORRECTED_END } = Events;

function NotCorrectedPanel(): JSX.Element {
    const {
        job: { instance: jobInstance, labels },
        annotations: { states },
        canvas: { instance: canvasInstance },
    }: AnnotationState = useSelector((state: CombinedState) => state.annotation);
    const dispatch = useDispatch();

    const defaultStatus: Status = {
        [NOT_CORRECTED_START]: false,
        [NOT_CORRECTED_END]: false,
    };

    const [status, setStatus] = useState<Status>(defaultStatus);

    useEffect(() => {
        const newStatus: Status = { ...defaultStatus };
        states.forEach((state) => {
            const labelName = state.label.name;
            if (labelName === NOT_CORRECTED_START) {
                newStatus[NOT_CORRECTED_START] = true;
            } else if (labelName === NOT_CORRECTED_END) {
                newStatus[NOT_CORRECTED_END] = true;
            }
        });
        setStatus(newStatus);
    }, [states]);

    const notCorrectedLabels = useMemo((): NotCorrectedLabels => ({
        [NOT_CORRECTED_START]: labels.find((label) => label.name === NOT_CORRECTED_START),
        [NOT_CORRECTED_END]: labels.find((label) => label.name === NOT_CORRECTED_END),
    }), [labels]);

    const disabledButtons = useMemo((): Status => ({
        [NOT_CORRECTED_START]: (!status[NOT_CORRECTED_START] && status[NOT_CORRECTED_END]) ||
        !notCorrectedLabels[NOT_CORRECTED_START],
        [NOT_CORRECTED_END]: (!status[NOT_CORRECTED_END] && status[NOT_CORRECTED_START]) ||
        !notCorrectedLabels[NOT_CORRECTED_END],
    }), [states, labels]);

    const removeObject = (sessionInstance: any, objectState: any): void => {
        dispatch(removeObjectAsync(sessionInstance, objectState, true));
    };

    const onNotCorrected = (key: string): void => {
        if (disabledButtons[key]) return;

        if (status[key]) {
            states.forEach((state) => {
                const labelName = state.label.name;
                if (labelName === key) {
                    removeObject(jobInstance, state);
                }
            });
        } else {
            canvasInstance.drawGhostBox({ label: notCorrectedLabels[key] });
        }
    };

    const onNotCorrectedStart = (): void => onNotCorrected(NOT_CORRECTED_START);
    const onNotCorrectedEnd = (): void => onNotCorrected(NOT_CORRECTED_END);

    return (
        <div className='cvat-annotation-header-not-corrected-panel'>
            <span>
                Not Corrected:
            </span>
            <div>
                <Button
                    type={status[NOT_CORRECTED_START] ? 'primary' : 'default'}
                    disabled={disabledButtons[NOT_CORRECTED_START]}
                    onClick={onNotCorrectedStart}
                >
                    Start
                </Button>
                <Button
                    type={status[NOT_CORRECTED_END] ? 'primary' : 'default'}
                    disabled={disabledButtons[NOT_CORRECTED_END]}
                    onClick={onNotCorrectedEnd}
                >
                    End
                </Button>
            </div>
        </div>
    );
}

export default React.memo(NotCorrectedPanel);
