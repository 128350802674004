// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
import { ControlPanelActions, ControlPanelActionTypes } from 'actions/control-panel-actions';
import { ControlPanelState } from './interfaces';

const defaultState: ControlPanelState = {
    isShowControlPanel: true,
    isAutopauseMode: false,
};

export default (state: ControlPanelState = defaultState, action: ControlPanelActions): ControlPanelState => {
    switch (action.type) {
        case ControlPanelActionTypes.TOGGLE_CONTROL_PANEL:
            return {
                ...state,
                isShowControlPanel: !state.isShowControlPanel,
            };
        case ControlPanelActionTypes.SHOW_AUTOPAUSE_MODE:
            return {
                ...state,
                isAutopauseMode: action.payload.isAutopauseMode,
            };
        default:
            return state;
    }
};
