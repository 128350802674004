// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
import Button from 'antd/lib/button';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StartMatchTimeAsync } from 'actions/start-match-button-action';
import serverProxy from 'cvat-core/src/server-proxy';

interface Props {
    startPosition: number;
}


function StartMatchButton({ startPosition }: Props): JSX.Element {
    const dispatch = useDispatch();
    const { requestedId } = useSelector((state) => state.annotation.job);

    async function handleClick() {
        dispatch(StartMatchTimeAsync(startPosition));
        await serverProxy.jobs.updateMatchStart(requestedId, { match_start: startPosition });
    }
    return (
        <div>
            <Button onClick={handleClick}>Match start</Button>
        </div>
    );
}

export default StartMatchButton;
