// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useEffect, useRef, useState } from 'react';

import Text from 'antd/lib/typography/Text';
import { EventsConstant } from 'reducers/interfaces';
import { normalizeCamelCase } from 'utils/helpers';
import Button from 'antd/lib/button';
import Alert from 'antd/lib/alert';
import Mousetrap from 'utils/mousetrap-record';
import consts from 'consts';
import { DEFAULT_EVENT_KEYS } from 'resources/default-events';
import { isNaN } from 'lodash';

interface EventsSettingsProps {
    notSavedEventKeys: EventsConstant[];
    setNotSavedEventKeys: Function;
}

function EventsSettingsComponent({
    notSavedEventKeys,
    setNotSavedEventKeys,
}: EventsSettingsProps): JSX.Element {
    const [validationError, setValidationError] = useState<boolean>(false);
    const [selected, setSelected] = useState<number | null>(null);
    const itemsRef: { current: HTMLElement[] } = useRef([]);

    const onUpdateEventKeys = (name: string, value: string): void => {
        const newEventKeys = notSavedEventKeys.map((eventKey) => {
            if (eventKey.name === name) {
                return { ...eventKey, sequences: [value] };
            }
            if (eventKey.sequences[0] === value) {
                return { ...eventKey, sequences: [''] };
            }
            return eventKey;
        });
        setNotSavedEventKeys(newEventKeys);
    };

    const onSetDefaultSettings = (): void => {
        setNotSavedEventKeys(DEFAULT_EVENT_KEYS);
    };

    useEffect(() => {
        if (typeof selected === 'number') {
            setValidationError(false);
            Mousetrap.record((sequences: string[]) => {
                const newValue = sequences?.length ? sequences[0] : null;

                if (newValue) {
                    const isSame = notSavedEventKeys[selected].sequences[0] === newValue;
                    const isForbidden = consts.FORBIDDEN_EVENT_KEYS.some((fKey) => fKey === newValue);

                    if (!isForbidden && !isSame) {
                        onUpdateEventKeys(notSavedEventKeys[selected].name, newValue);
                    } else if (isForbidden) {
                        setValidationError(true);
                    }
                }

                itemsRef.current.forEach((el) => el.blur());
            });
        } else {
            Mousetrap.finishRecording();
        }
    }, [selected]);

    return (
        <div className='cvat-events-settings'>
            <div className='cvat-events-settings-header'>
                <Text>Event hotkeys:</Text>
                <Button
                    onClick={onSetDefaultSettings}
                    danger
                >
                    Set default settings
                </Button>
            </div>
            <div className='cvat-events-settings-container'>
                {notSavedEventKeys.map(({ name, sequences }, idx) => (
                    <div
                        key={`cvat-events-settings-cell-${idx}`}
                        className='cvat-events-settings-cell'
                    >
                        <Text type='secondary'>
                            {normalizeCamelCase(name)}
                        </Text>
                        <Button
                            onFocus={() => setSelected(idx)}
                            onBlur={() => setSelected(null)}
                            ref={(el: HTMLElement) => {
                                itemsRef.current[idx] = el;
                            }}
                            danger={!sequences[0]}
                        >
                            {sequences[0] || '???'}
                        </Button>
                    </div>
                ))}
            </div>
            {validationError && (
                <Alert
                    className='cvat-events-settings-validation-error'
                    message={`
                    Sorry. You can't use following characters: ${consts.FORBIDDEN_EVENT_KEYS
                    .filter((key) => isNaN(+key))
                    .map((key) => key.toUpperCase())
                    .join(', ')} and numbers.
                    `}
                    type='error'
                />
            )}
        </div>
    );
}

export default React.memo(EventsSettingsComponent);
