import { AnyAction } from 'redux';



export enum StartMatchTimeTypes {
    START_MATCH_TIME = 'START_MATCH_TIME',
}



export function StartMatchTimeAsync(time: number): AnyAction {
    return {
        type: StartMatchTimeTypes.START_MATCH_TIME,
        payload: time
    };
}